/**
 * Form Validation
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

import $ from 'jquery';
// eslint-disable-next-line import/no-unassigned-import
import 'jquery-validation';

const $form = $('#anfrage, .admin.login form');

// Handle form validation.
if ($form.length > 0) {
	$form.validate({
		focusInvalid: false,
		messages: {
			reason: 'Bitte wählen Sie einen Grund für Ihre Anfrage aus.',
			name: 'Bitte geben Sie Ihren Vor- und Nachnamen an.',
			'street-address': 'Bitte geben Sie Ihre Adresse an.',
			'postal-code': 'Bitte geben Sie Ihre Postleitzahl an.',
			city: 'Bitte geben Sie Ihren Wohnort an.',
			locality: 'Bitte geben Sie Ihren Wohnort an.',
			phone: 'Bitte geben Sie Ihre Telefonnummer an.',
			email: {
				required: 'Bitte geben Sie Ihre E-Mail-Adresse an.',
				email: 'Ihre E-Mail-Adresse muss das Format mail@example.com haben.',
			},
			message: 'Ihre Nachricht ist zu kurz.',
			privacy: 'Sie müssen der Datenschutzerklärung zustimmen.',
			username: 'Bitte geben Sie Ihren Benutzernamen an.',
			password: 'Bitte geben Sie Ihr Passwort an.',
		},
		errorPlacement(error, elm) {
			const name = $(elm).attr('name');
			if (name === 'reason' || name === 'privacy') {
				error.appendTo(elm.parent().parent());
			} else {
				error.appendTo(elm.parent());
			}
		},
	});

	// Scroll to first error
	if ($('[role="alert"]', $form).length > 0) {
		window.scrollTo(0, $('[role="alert"]', $form).offset().top - 100);
	}
}
